import { client, clientForTransactions } from '../../api/fdApi/client';
import TransactionRecord, {
  DepositsTransactionRecord,
  MutualFundTransactionRecord,
  MutualFundsTransactionsOut,
  TransactionsOut,
} from '../types';
interface TransactionQueryParams {
  search_text?: string | null;
  from_date?: Date | string | null;
  to_date?: Date | string | null;
  offset?: string;
  limit?: number;
  summary?: boolean;
  detail?: boolean;
  exclude_rejected?: boolean;
}
type asset_class = 'Mutual Funds' | 'Deposits';

enum transactions_endpoint {
  'Mutual Funds' = '/orders',
  'Deposits' = '/transactions',
}

/**
 *  getUserTransictions() generic function which will fetch transaction repective to passes asset_class and returns Promise with TransactionsOut interface
 * @param asset_class
 * @param queryParams
 * @param uri
 * @returns Promise<TransactionsOut>
 */
async function getUserTransictions(
  asset_class: asset_class,
  queryParams?: TransactionQueryParams,
  uri?: string | null
) {
  // ---------- Query String from parameters -------------
  let queryString;
  if (queryParams) {
    if (asset_class === 'Deposits') {
      queryParams.detail = true;
      queryParams.summary = false;
      queryParams.exclude_rejected = false;
    }
    queryString = Object.entries(queryParams)
      .filter(([, value]) => ![null, undefined, ''].includes(value))
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join('&');
  }

  // URL based on ASSET CLASS
  const url = queryString
    ? `${transactions_endpoint[asset_class]}?${queryString}`
    : `${transactions_endpoint[asset_class]}`;

  if (asset_class === 'Mutual Funds') {
    return await getMutualFundsTransactions(uri ? uri : url);
  }

  if (asset_class === 'Deposits') {
    return await getDepositsTransactions(uri ? uri : url);
  }
}

/**
 * Purpose of getMutualFundsTransactions() is to just fetch and normalize the recieved reponse from api ***********
 * @param url
 * @returns
 */

const getMutualFundsTransactions = async (
  url: string
): Promise<TransactionsOut> => {
  const res = await clientForTransactions.get<MutualFundsTransactionsOut>(url);
  const results: TransactionRecord[] = res.data.results.map(
    (record: MutualFundTransactionRecord) => {
      return {
        id: record.id,
        user_id: record.investor_id,
        date: record.date,
        record_id: record.fund_id,
        asset_name: record.fund_name,
        amount: record.amount,
        order_status: record.status,
        order_type: record.order_type,
        folio: record.folio,
        units: record.units,
        nav: record.nav,
      };
    }
  );
  const normalizedReponse: TransactionsOut = {
    results: results,
    limit: res.data.limit,
    next: res.data?.next || null,
    previous: res.data?.previous || null,
  };
  return normalizedReponse;
};

/**
 * Purpose of getDepositsTransactions() is to just fetch and normalize the recieved reponse from api ***********
 * @param url
 * @returns
 */
const getDepositsTransactions = async (url: string) => {
  const res = await client.get<{ detail: DepositsTransactionRecord[] }>(url);

  const results: TransactionRecord[] = res.data.detail.map(
    (record: DepositsTransactionRecord) => {
      let order_type;
      if (record.interest_payout_frequency === 'maturity') {
        order_type = 'cumulative';
      } else {
        order_type = 'non_cumulative';
      }
      return {
        id: record.id,
        user_id: record.user_id,
        date: record.created_at,
        record_id: record.issuer_id,
        asset_name: record?.issuer?.name,
        amount: record.amount,
        order_status: record.tarrakki_status,
        tenure: record.tenure,
        tenure_in_days:record.tenure_in_days,
        tenure_in_years:record.tenure_in_years,
        interest_rate: record.interest_rate,
        order_type: order_type,
        deposits_tarraki_id: record.tarrakki_id,
        issuer_id: record.issuer_id,
        maturity_amount: record.maturity_amount,
        maturity_date: record.maturity_date,
        applicant: record?.applicant,
      };
    }
  );
  const normalizedReponse: TransactionsOut = {
    results: results,
    limit: null,
    next: null,
    previous: null,
  };
  return normalizedReponse;
};

export default getUserTransictions;
